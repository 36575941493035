import React from "react";
import {
  createGlobalStyle,
  css,
  ThemeProvider as SCThemeProvider,
} from "styled-components";
import { Normalize } from "styled-normalize";
import PropTypes from "prop-types";

import "../../fonts/fonts.css";
import "@brainhubeu/react-carousel/lib/style.css";

const Globals = createGlobalStyle`
  a, a:visited, a:hover {
    color: inherit;
    text-decoration: none;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  body, html {
    overflow-x: hidden
  }
`;

function spacingHelper(value) {
  return value === "" || value === undefined
    ? ""
    : // eslint-disable-next-line no-use-before-define
      `${value * theme.spacing.unit}px`;
}

export const theme = {
  forms: {
    height: css`
      height: 37px;
    `,
    border: css`
      border: 1px solid black;
    `,
  },
  text: {
    family: {
      title: css`
        font-family: "Kinder";
        letter-spacing: -0.04em;
      `,
      subtitle: css`
        font-family: "Big Caslon";
        letter-spacing: 0.04em;
      `,
      body: css`
        font-family: "Libre Baskerville";
        line-height: 1.5em;
      `,
    },
    size: {
      xl: css`
        font-size: 3rem;
      `,
      l: css`
        font-size: 1.5rem;
      `,
      m: css`
        font-size: 1rem;
      `,
      s: css`
        font-size: 0.8rem;
      `,
      xs: css`
        font-size: 0.5rem;
      `,
    },
  },
  spacing: {
    unit: 4,
    padding: (top, right, bottom, left) =>
      css`
        padding: ${spacingHelper(top)} ${spacingHelper(right)}
          ${spacingHelper(bottom)} ${spacingHelper(left)};
      `,
    margin: (top, right, bottom, left) =>
      css`
        margin: ${spacingHelper(top)} ${spacingHelper(right)}
          ${spacingHelper(bottom)} ${spacingHelper(left)};
      `,
  },
  // prettier-ignore
  media: {
    mobile: css`
      @media (max-width: 768px)
    `,
    desktop: css`
      @media (min-width: 768px)
    `,
  },
};

const ThemeProvider = ({ children }) => (
  <SCThemeProvider theme={theme}>
    <Normalize />
    <Globals />
    {children}
  </SCThemeProvider>
);

ThemeProvider.propTypes = {
  /**
   * The content to receive the theme.
   */
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
