import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Text from "./text";
import { ReactComponent as BoxIcon } from "../../svgs/checkbox.svg";
import { ReactComponent as CheckedBoxIcon } from "../../svgs/checkbox-checked.svg";

const HiddenInput = styled.input`
  display: none;
`;

const Box = styled.svg`
  width: auto;
  height: 0.7em;
  margin: auto 0.4em 0 0;
  overflow: visible;
`;

const StyledLabel = styled.label`
  user-select: none;
`;

const Checkbox = ({ onChange, label, checked, ...other }) => {
  return (
    <StyledLabel>
      <HiddenInput
        type="checkbox"
        onChange={onChange}
        checked={checked}
        {...other}
      />
      <Box as={checked ? CheckedBoxIcon : BoxIcon} />
      <Text font="subtitle">{label}</Text>
    </StyledLabel>
  );
};

Checkbox.propTypes = {
  /**
   * A label text describing what the checkbox is for.
   */
  label: PropTypes.node,
  /**
   * A function to be called when there is any change to the input. Receives
   * the new value as first parameter, and the event
   * that originated the change as second.
   */
  onChange: PropTypes.func,
  /**
   * The controlled value of the checkbox.
   */
  value: PropTypes.bool,
};

export default Checkbox;
