/* eslint-disable import/prefer-default-export */

import React from "react";
import ThemeProvider from "./src/components/common/theme-provider";
import { PreviewStoreProvider } from "gatsby-source-prismic";
import { NewsletterActionProvider } from "./src/components/common/newsletter";

// You can delete this file if you're not using it
export function wrapPageElement({ element }) {
  return <ThemeProvider>{element}</ThemeProvider>;
}

async function newsletterSubscribe(email) {
  const data = {
    u: "6539965b0dedaf360083cfff5",
    id: "d5bc80279c",
    EMAIL: email,
  };
  const urlEncoded = new URLSearchParams(data);
  return await window.fetch(
    "https://xaga.us13.list-manage.com/subscribe/post",
    {
      body: urlEncoded,
      method: "POST",
      mode: "no-cors",
    }
  );
}

export const wrapRootElement = ({ element }) => (
  <NewsletterActionProvider value={newsletterSubscribe}>
    <PreviewStoreProvider>{element}</PreviewStoreProvider>
  </NewsletterActionProvider>
);
