import React, { createContext, useContext, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Input from "./input";
import Button from "./button";
import Checkbox from "./checkbox";
import Text from "./text";

const StyledForm = styled.form``;

const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  & > button {
    border-left: none;
  }
  & input {
    width: 100%;
  }
`;

const Privacy = styled.div`
  padding-top: 4px;
`;

const NewsletterActionContext = createContext(() => {});
export const NewsletterActionProvider = NewsletterActionContext.Provider;

const Newsletter = () => {
  const [email, setEmail] = useState();
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState("initial");
  const onSubmit = useContext(NewsletterActionContext);
  return (
    <StyledForm
      onSubmit={async (event) => {
        event.preventDefault();
        if (email && status === "initial") {
          setStatus("sending");
          const minimumWait = new Promise((resolve) =>
            setTimeout(resolve, 1500)
          );
          const response = onSubmit(email);
          try {
            await Promise.all([minimumWait, response]);
            setStatus("done");
          } catch (e) {
            setStatus("error");
            console.log(e);
          }
        }
      }}
      autoComplete="off"
    >
      <Flex>
        <Input
          onChange={setEmail}
          value={email}
          label="newsletter"
          placeholder="email"
          type="email"
          name="email"
        />
        <Button
          name="submit"
          type="submit"
          disabled={
            status !== "initial" ||
            !checked ||
            !/[^@]+@[^.@]+\.[^@]+/.test(email)
          }
        >
          {status === "initial"
            ? "send"
            : status === "sending"
            ? "sending..."
            : status === "done"
            ? "done!"
            : status === "error"
            ? "error :("
            : null}
        </Button>
      </Flex>
      <Privacy>
        <Checkbox
          label={
            <Text font="subtitle" size="s">
              I have read and accept the{" "}
              <Link to="/legal/terms-and-conditions">terms and conditions</Link>{" "}
              and <Link to="/legal/privacy-policy">privacy policy</Link>.
            </Text>
          }
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
          name="privacy-policy"
          required
        />
      </Privacy>
    </StyledForm>
  );
};

Newsletter.propTypes = {
  /**
   * A function to be called when the form is submitted with a valid email.
   */
  onSubmit: PropTypes.func,
  // /**
  //  * A function to be called when the form is submitted with an invalid email.
  //  */
  // onError: PropTypes.func,
};

export default Newsletter;
