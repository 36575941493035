import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Text from "./text";

const StyledButton = styled.button`
  background-color: transparent;
  ${({ theme }) => theme.forms.border};
  ${({ theme }) => theme.spacing.padding(0, 4)};
  margin: 0;
  ${({ theme }) => theme.forms.height};
`;

const Button = ({ children, ...other }) => (
  <StyledButton {...other}>
    <Text font="subtitle">{children}</Text>
  </StyledButton>
);

Button.propTypes = {
  /**
   * The callback to be fired when the button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The content of the button.
   */
  children: PropTypes.node,
};

export default Button;
