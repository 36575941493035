import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledText = styled.span`
  & a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid black;
  }
  margin: 0;
  padding: 0;
  font-weight: normal;
  ${({ theme, font }) => theme.text.family[font]};
  ${({ theme, size }) => theme.text.size[size]};
`;

const Text = ({
  children,
  className,
  component = "span",
  font = "body",
  size = "m",
  ...rest
}) => (
  <StyledText
    className={className}
    as={component}
    font={font}
    size={size}
    {...rest}
  >
    {children}
  </StyledText>
);

Text.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node.isRequired,
  /**
   *  A class name to be passed.
   */
  className: PropTypes.string,
  /**
   * The actual component inside which the text is rendered.
   */
  component: PropTypes.elementType,
  /**
   * The type of font to use.
   */
  font: PropTypes.oneOf(["title", "subtitle", "body"]),
  /**
   * The visual size of the text.
   */
  size: PropTypes.oneOf(["xl", "l", "m", "s", "xs"]),
};

export default Text;
