import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import Text from "./text";

const StyledInput = styled.input`
  background-color: transparent;
  border-radius: 0;
  ${({ theme }) => theme.forms.border};
  ${({ theme }) => theme.spacing.padding(0, 4)};
  margin: 0;
  ${({ theme }) => theme.forms.height};
  &::placeholder {
    color: gray;
    opacity: 1;
  }
`;

const StyledLabel = styled.label`
  display: block;
  padding-bottom: 8px;
`;

const Input = ({
  placeholder: placeholderProp,
  id: idProp,
  onChange,
  label,
  ...other
}) => {
  const [placeholder, setPlaceholder] = useState(placeholderProp);
  useEffect(() => setPlaceholder(placeholderProp), [placeholderProp]);

  const [id, setID] = useState(() => idProp || uuid());
  useEffect(() => setID(idProp || uuid()), [idProp]);

  return (
    <Text font="subtitle">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {label && (
        <StyledLabel htmlFor={id}>
          <Text font="subtitle" size="s">
            {label}
          </Text>
        </StyledLabel>
      )}
      <StyledInput
        {...other}
        id={id}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value, event)}
        onFocus={() => setPlaceholder("")}
        onBlur={() => setPlaceholder(placeholderProp)}
      />
    </Text>
  );
};

Input.propTypes = {
  /**
   * A placeholder to suggest an example input to the user.
   */
  placeholder: PropTypes.string,
  /**
   * A label text describing what the input is for.
   */
  label: PropTypes.node,
  /**
   * A function to be called when there is any change to the input. Receives
   * the new value as first parameter, and the event
   * that originated the change as second.
   */
  onChange: PropTypes.func,
  /**
   * The controlled value of the input
   */
  value: PropTypes.string,
};

export default Input;
